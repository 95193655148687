<template>
    <div>
        <div class="wrapper">
            <div class="container-fluid d-flex">
                <aside>
                    <side-bar-three></side-bar-three>
                </aside>
                <div class="main-cont">
                    <div class="container-fluid">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SideBarThree from '@/components/SidebarThree.vue'
export default {
    components:{
        SideBarThree
    }
}
</script>