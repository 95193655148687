<template>
    <div>
        <div class="wrapper">
            <b-overlay :show="sideBarLoading" rounded="sm">
                <div class="container-fluid d-flex">
                    <aside>
                        <side-bar-two></side-bar-two>
                    </aside>
                    <div class="main-cont">
                        <div class="container-fluid">
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import SideBarTwo from '@/components/SidebarTwo.vue';
import { mapGetters } from "vuex";
export default {
    components:{
        SideBarTwo
    },
    computed:{
        ...mapGetters([
            "sideBarLoading",
        ]),
    },
}
</script>