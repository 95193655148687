<template>
    <div class="hidden-print">
        <div class="side-navbar">
            <div class="navbar-top text-center">
                <!-- <a href=""> -->
                    <img :src='logo' alt="Lek_Auto_logo" class="img-fluid" />
                    <!-- </a> -->
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/dashboard">
                        <div class="nav-box" :class="[isActive('/dashboard') ? 'active' : '' ]">
                            <i class="fa fa-home" aria-hidden="true"></i>
                            <p>Home</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/sale">
                        <div class="nav-box" :class="[isActive('/sale') ? 'active' : '' ]">
                            <i class="fa fa-tag" aria-hidden="true"></i>
                            <p>Sale</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/customer">
                        <div class="nav-box" :class="[isActive('/customer') ? 'active' : '' ]">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            <p>Customer</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/appointment">
                        <div class="nav-box" :class="[isActive('/appointment') ? 'active' : '' ]">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            <p>Appointment</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/transactions">
                        <div class="nav-box" :class="[isActive('/transactions') ? 'active' : '' ]">
                            <i class="fa fa-exchange" aria-hidden="true"></i>
                            <p>Transactions</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/products">
                        <div class="nav-box" :class="[isActive('/products') ? 'active' : '' ]">
                            <i class="fa fa-cubes" aria-hidden="true"></i>
                            <p>Products</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/services">
                        <div class="nav-box" :class="[isActive('/services') ? 'active' : '' ]">
                            <i class="fa fa-scissors" aria-hidden="true"></i>
                            <p>Services</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/reports">
                        <div class="nav-box" :class="[isActive('/reports') ? 'active' : '' ]">
                            <i class="fa fa-scissors" aria-hidden="true"></i>
                            <p>Reports</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                logo: require("@/assets/images/logo.png"),
            }
        },
        methods:{
            isActive(path) {
                return this.$route.path == path || this.$route.path.includes(path);
            }
        },
        computed:{
            is_current_path(){
                return this.$store.state.is_current_path
            }
        },
        created(){}
    }
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/style.scss";
@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
</style>