<template>
    <div>
        <div class="side-navbar">
            <router-link to="/customer" style="color: #fff; background-color: #6c757d; padding: 3px 10px; border-radius: 5px;"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
            <div class="navbar-top d-flex">
                <!-- <router-link to="/dashboard"> -->
                <img :src="logo" alt="Lek_Auto_logo" class="img-fluid" />
                <!-- </router-link> -->
            </div>
            <ul class="nav nav-pills mt-3 mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button @click="navLinkOnclick('pills-home-tab')" :class="{ active : active_el == 'pills-home-tab'}" class="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Services</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="navLinkOnclick('pills-profile-tab')" :class="{ active : active_el == 'pills-profile-tab'}" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">Products</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="navLinkOnclick('pills-contact-tab')" :class="{ active : active_el == 'pills-contact-tab'}" class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Packages</button>
                </li>
            </ul>
            <div class="tab-content categories-data" id="pills-tabContent">
                 <div class="tab-pane fade show" :class="{ active : active_el == 'pills-home-tab'}" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item" v-for="(category_list, index) of getCategoryList" :key="index" >
                            <h2 class="accordion-header" :id="'flush-headingOne' + index">
                                <button @click="getSubCategories(category_list.SERVICE_CATEGORY_SYS_ID)"  v-b-toggle="'category-'+category_list.SERVICE_CATEGORY_SYS_ID" class="accordion-button collapsed"  type="button">
                                    {{category_list.SERVICE_CATEGORY_NAME}}
                                </button>
                            </h2>

                            <b-collapse :id="'category-'+category_list.SERVICE_CATEGORY_SYS_ID" role="tabpanel" accordion="my-accordion">
                                <div class="accordion-body">
                                    <div class="accordion-item" v-for="(sub_category, sub_category_index) of getSubCategoryList" :key="sub_category_index">
                                        <h2 class="accordion-header">
                                            <button @click="getservicelist(sub_category.SERVICE_SUB_CATEGORY_SYS_ID)" style="background: #d6caa7 !important;" v-b-toggle="'service-'+sub_category.SERVICE_SUB_CATEGORY_SYS_ID" class="accordion-button collapsed mb-1" type="button">
                                                {{sub_category.SERVICE_SUB_CATEGORY_NAME}}
                                            </button>
                                        </h2>
                                        <b-collapse :id="'service-'+sub_category.SERVICE_SUB_CATEGORY_SYS_ID" role="tabpanel" accordion="my-accordion-1">
                                            <div class="row">
                                                <div  class="col-md-6 p-0 mb-1" style="padding-right:2px !important; padding-left:2px !important" v-for="(service, service_index) of getServiceList" :key="service_index">
                                                    <div class="pro-box2" @click="getData(service,'SERVICE',sub_category.SERVICE_SUB_CATEGORY_NAME)">
                                                        <label class="frm-label pb-0">{{service.ITEM_NAME}}</label><p style="color:#976b03;">$ {{service.SELL_PRICE}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div> 
                
                <div class="tab-pane fade show"  :class="{ active : active_el == 'pills-profile-tab'}" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item" v-for="(DynamicProductArray, index) of getProductsList" :key="index">
                            <h2 class="accordion-header" id="flush-heading4">
                            <button v-b-toggle="'product-'+DynamicProductArray.ITEM_CATEGORY_ID" class="accordion-button collapsed" type="button">
                                {{DynamicProductArray.ITEM_CATEGORY_NAME}}
                            </button>
                            </h2>
                            <b-collapse :id="'product-'+DynamicProductArray.ITEM_CATEGORY_ID" role="tabpanel" accordion="my-accordion">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-md-6 p-0 mb-1" style="padding-right:2px !important; padding-left:2px !important" v-for="(SubServiceDynamicFlag, index) of DynamicProductArray.PRODUCT_INFO" :key="index">
                                            <div class="pro-box2" @click="getData(SubServiceDynamicFlag,'PRODUCt',DynamicProductArray.ITEM_CATEGORY_NAME)">
                                                <label class="frm-label pb-0">{{SubServiceDynamicFlag.ITEM_NAME}}</label><p style="color:#976b03;">$ {{SubServiceDynamicFlag.SELL_PRICE}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <b-modal id="pac-info-modal" size="sm" v-model="pac_info_modal" centered content-class="shadow" title="Information">
            <p class="my-2">
                Package cannot be billed without Customer name.
            </p>
            <template #modal-footer="{ok}">
                <div style="text-align:right">
                    <button  class="btn btn-md btn-secondary" @click="ok()"> OK</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
    export default {
        data(){
            return{
                logo: require("@/assets/images/logo.png"),
                  tax_info_details:{},
                  DynamicFlagArray:[],
                  DynamicPackagesArray:[],
                  active_el:"",
                  service_active_el:"",
                  product_active_el:"",
                  pac_info_modal:false,
            }
        },
    
        mounted() {
            this.navLinkOnclick('pills-home-tab');
        },
        computed:{
            ...mapGetters([
                "getProducts",
                "getProductsList",
                "getCategoryList",
                "getSubCategoryList",
                "getServiceList"
            ]),
        },

        methods:{
            //Nav Link OnClick
            navLinkOnclick:function(el){
                this.pac_info_modal = false;
                this.active_el = el;
                if (el == 'pills-home-tab') {
                    if (this.getCategoryList.length ===0) {
                        this.getcategorylist();
                    }
                }
                if (el == 'pills-profile-tab') {
                    if (this.getProductsList.length === 0) {
                        this.getproductlist();
                    }
                }
                if (el == 'pills-contact-tab') {
                    if (!this.$route.query.ORDER_ID && !this.$route.params.CUSTOMER_NAME) {
                        this.$store.commit('clickPackages', false);
                        this.pac_info_modal = true;
                    } else {
                        this.$store.commit('clickPackages', true);
                        this.pac_info_modal = false;
                    }
                } else {
                    this.$store.commit('clickPackages', false);
                }
            },

            //CATEGORY LIST 
            getcategorylist() {
                let category_list_details = [];
                this.$store.commit("setSidebarLoading", true);
                this.$apiCall.get(this.$apiBus.category_list, {
                    params : {
                        ITEM: "VIEW_ALL",
                        RECORD_SYS_ID: 0,
                    }
                }).then(res => {
                    if (res.status == 'true') {
                        category_list_details = res.response
                    } else {
                        category_list_details = [];
                    }
                    this.$store.commit('addCategories', category_list_details);
                    this.$store.commit("setSidebarLoading", false);
                }).catch(err =>{
                    this.$store.commit("addCategories", []);
                })
            },

            getSubCategories(sys_id){
                let sub_category_list = [];
                this.$forceUpdate();
                if (sys_id) {
                    this.$store.commit("setSidebarLoading", true);
                    this.$apiCall.get(this.$apiBus.get_sub_categories, {
                        params : {
                            ITEM: "SPECIFIC",
                            RECORD_SYS_ID: sys_id
                        }
                    }).then(res => {
                        if (res.status == 'true') {
                            sub_category_list= res.response;
                        } else {
                            sub_category_list = [];
                            this.$forceUpdate()
                        }
                        this.$store.commit("addSubCategories", sub_category_list);
                        this.$store.commit("setSidebarLoading", false);
                    }).catch(err =>{
                        this.$store.commit("addSubCategories", []);
                    })
                }
            },

            //SERVIICE DATA SHOW
            getservicelist(sys_id) {
                let services_list = [];
                if (sys_id) {
                    this.$store.commit("setSidebarLoading", true);
                    this.$apiCall.get(this.$apiBus.ViewServiceInfo, {
                        params : {
                            ITEM: "VIEW_ALL",
                            SUB_CATEGORY_SYS_ID: sys_id
                        }
                    }).then(res => {
                        if (res.status == 'true') {
                            services_list = res.response;
                        } else {
                            services_list = [];
                        }
                        this.$store.commit('addServices', services_list);
                        this.$store.commit("setSidebarLoading", false);
                    }).catch(err =>{
                        this.$store.commit("addServices", []);
                    })
                }
            },

            getproductlist() {
                this.$store.commit("setSidebarLoading", true);
                this.$apiCall.get(this.$apiBus.product_list, {
                    params : {
                        ITEM: "VIEW_ALL",

                        RECORD_SYS_ID:0,
                        PRODUCT_SKU_CODE: "",

                        BRAND_NAME:"",
                        SERIES_NAME:"",
                        PRODUCT_TYPE:"",
                        BAR_CODE:"",
                        PRODUCT_NAME:""
                    }
        
                }).then(res => {
                    this.tax_info_details = res.response;
                    let DynamicproductFlagArray = res;
                    this.$store.commit('addProductsList', DynamicproductFlagArray);
                    this.$store.commit("setSidebarLoading", false);
                }).catch(err =>{
                    this.$store.commit('addProductsList', DynamicproductFlagArray);
                })
            },

            //Get Packages Info
            GetPackagesInfo(){
                this.$apiCall.get(this.$apiBus.ViewPackagesInfo, {
                params : {
                    ITEM: "SPECIFIC",
                    BRANCH_SYS_ID:"1"
                }
                }).then(res => {
                    this.DynamicPackagesArray = res;
               });
            },

            getData(services,itemType,itemCategoryName){
                console.log(services, 'service');
                // return
                let items = JSON.parse(JSON.stringify(this.getProducts));
                const serviceObj = services;
                serviceObj['ORDER_DETAILS_ID']= 0;
                serviceObj['ITEM_SKU_CODE'] = services.SKU_REF_CODE;
                serviceObj['QTY'] = 1;
                serviceObj['CURRENCY_SYS_ID'] = 1;
                serviceObj['UNIT_PRICE'] = serviceObj.SELL_PRICE;
                serviceObj['DISCOUNT_VALUE']= 0;
                serviceObj['TOTAL_PRICE'] = serviceObj.SELL_PRICE;
                serviceObj["ITEM_TYPE"] = itemType;

                serviceObj["ITEM_CATEGORY_NAME"] = itemCategoryName;
                if (items.length > 0) {
                    let cart_item = items.find(i => (i.ITEM_ID == serviceObj.ITEM_ID && i.ITEM_TYPE == serviceObj.ITEM_TYPE));
                    if (cart_item) {
                        let index = items.indexOf(cart_item);
                        items[index].QTY += 1;
                        items[index].TOTAL_PRICE = (parseInt(items[index].QTY) * parseInt(items[index].UNIT_PRICE));
                        this.$store.commit("updateCart", items);
                        this.$forceUpdate();
                    } else {
                        items.push(serviceObj);
                        this.$forceUpdate();
                        this.$store.commit('addProducts', items);
                    }
                } else {
                    items.push(serviceObj);
                    
                    this.$store.commit('addProducts', items);
                    this.$forceUpdate();
                }

                console.log(items, 'items');
                
            },


        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/style.scss";
.side-navbar .active {
    color: #fff;
    background-image: linear-gradient(to right, #BD9B36 , #976b03) !important;
}
.wrapper .side-navbar ul li {
    padding: 0px;
    margin-top: 0px;
    border-bottom: 0px;
    box-shadow:none;
    color: #212121;
}
.wrapper .side-navbar li:hover {
    background: none;
    color: #212121;

}
.nav-link:hover, .nav-link:focus {
    color: #212121 !important;
}
.nav-link:hover, .nav-link:focus {
    color: #212121;
}
.categories-data .active {
    background: none !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}
</style>