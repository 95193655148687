const api_url = process.env.VUE_APP_API_URL;
const endpoints = {
    login: api_url + "Authentication/api-login",
    ViewServiceInfo:  api_url + "WebApi/api-get-view-services-info",
    get_customer: api_url + "WebApi/api-get-view-customer-info",
    add_customer: api_url + "WebApi/api-post-add-update-customer-info",
    update_customer: api_url + "WebApi/api-post-add-update-customer-info",
    ViewProductInfo:  api_url + "WebApi/api-get-view-product-info",
    category_list:api_url + "WebApi/api_get_view_service-category-name-info",
    product_list:api_url + "WebApi/api-get-view-product-info",
    ViewPackagesInfo:api_url + "WebApi/api-get-view-package-info",
    addapoitment:api_url + "WebApi/api-post-add-update-appointment-info",
    employee:api_url + "WebApi/api-get-view-branch-user-info",
    view_appoitmeant:api_url + "WebApi/api-get-view-branch-user-info",
    add_service:api_url + "WebApi/api_post_add_update_service_transaction_info",
    ViewTransactionInfo:api_url + "WebApi/api-get-view-transaction-details",
    ViewIncompletetransaction:api_url + "WebApi/api-get-view-transaction-details",
    place_order: api_url + "WebApi/api_post_add_update_pos_order_info",
    confirm_order: api_url + "WebApi/api_post_add_update_pos_order_info",
    order_details:api_url + "WebApi/api-get-view-order-info",
    add_update_package_info:api_url + "WebApi/api_post_add_update_package_info",
    get_package_category_info:api_url + "WebApi/api_get_view_package_category_info",
    get_payment_mothods:api_url + "WebApi/api_get_view_payment_mode",
    get_appointment_details:api_url + "WebApi/api-get-view-appointment-info",
    get_sub_categories: api_url + "WebApi/api-get-view-service-sub-category-name-info",
    get_appointment_details_range_wise: api_url + "WebApi/api-get-view-appointment-range-specific-info",
    get_customer_package_info:api_url + "WebApi/api-get-view-customer-package-info",
    get_staff_salesreport_info:api_url + "WebApi/api-view-staff-sales-report",
    get_customer_transaction_info:api_url + "WebApi/api-get-view-customer-transaction-info",
    add_medical:api_url + "WebApi/api-post-add-update-customer-medical-record",
    view_medical:api_url + "WebApi/api-get-view-customer-medical-record",
    complete_event:api_url + "WebApi/api_post_add_update_cust_package_transaction_info",
    get_staff_servicesreport_info:api_url + "WebApi/api-view-staff-service-report",
    get_staff_productsalesreport_info:api_url + "WebApi/api-view-product-sales-report",
    get_view_working_employee_data: api_url + "WebApi/api-get-view-employee-working-roster-info",
    add_update_customer_wallet: api_url + "WebApi/api-post-add-update-customer-wallet",
    
};

export default endpoints;