<template>
    <div>
        <div class="side-navbar">
            <router-link to="/packages" style="color: #fff; background-color: #6c757d; padding: 3px 10px; border-radius: 5px;"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
            <div class="navbar-top d-flex">
                <img :src="logo" alt="Lek_Auto_logo" class="img-fluid" />
            </div>
            <ul class="nav nav-pills mt-3 mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button @click="navLinkOnclick('pills-home-tab')" :class="{ active : active_el == 'pills-home-tab'}" class="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Services</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="navLinkOnclick('pills-profile-tab')" :class="{ active : active_el == 'pills-profile-tab'}" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">Products</button>
                </li>
                <!-- <li class="nav-item" role="presentation">
                    <button @click="navLinkOnclick('pills-contact-tab')" :class="{ active : active_el == 'pills-contact-tab'}" class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Packages</button>
                </li> -->
            </ul>
            <div class="tab-content categories-data" id="pills-tabContent">
                 <div class="tab-pane fade show" :class="{ active : active_el == 'pills-home-tab'}" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item" v-for="(ServiceDynamicFlagArray, index) of DynamicFlagArray" :key="index">
                            <h2 class="accordion-header" :id="'flush-headingOne' + index">
                                <button v-b-toggle="'service-'+ServiceDynamicFlagArray.ITEM_CATEGORY_ID" class="accordion-button collapsed" type="button">
                                    {{ServiceDynamicFlagArray.ITEM_CATEGORY_NAME}}
                                </button>
                            </h2>
                            <b-collapse :id="'service-'+ServiceDynamicFlagArray.ITEM_CATEGORY_ID" role="tabpanel">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div  class="col-md-6 p-0" v-for="(SubServiceDynamicFlagArray, index) of ServiceDynamicFlagArray.SERVICE_INFO" :key="index">
                                            <div class="pro-box2" @click="getDataForPackages(SubServiceDynamicFlagArray,'Service',ServiceDynamicFlagArray.ITEM_CATEGORY_NAME)">
                                                <label class="frm-label pb-0">{{SubServiceDynamicFlagArray.ITEM_NAME}}</label><p style="color:#976b03;">$ {{SubServiceDynamicFlagArray.SELL_PRICE}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div> 
                
                <div class="tab-pane fade show"  :class="{ active : active_el == 'pills-profile-tab'}" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item" v-for="(DynamicProductArray, index) of DynamicproductFlagArray" :key="index">
                            <h2 class="accordion-header" id="flush-heading4">
                            <button v-b-toggle="'product-'+DynamicProductArray.ITEM_CATEGORY_ID" class="accordion-button collapsed" type="button">
                                {{DynamicProductArray.ITEM_CATEGORY_NAME}}
                            </button>
                            </h2>
                            <b-collapse :id="'product-'+DynamicProductArray.ITEM_CATEGORY_ID" role="tabpanel">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-md-6 p-0" v-for="(SubServiceDynamicFlag, index) of DynamicProductArray.PRODUCT_INFO" :key="index">
                                            <div class="pro-box2" @click="getDataForPackages(SubServiceDynamicFlag,'Product',DynamicProductArray.ITEM_CATEGORY_NAME)">
                                                <label class="frm-label pb-0">{{SubServiceDynamicFlag.ITEM_NAME}}</label><p style="color:#976b03;">$ {{SubServiceDynamicFlag.SELL_PRICE}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div>

                <!-- <div class="tab-pane fade show" :class="{ active : active_el == 'pills-contact-tab'}" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div class="row">
                        <div class="col-md-6 p-1">
                            <div class="pro-box2">
                                <label class="frm-label pb-0">Senior Eyebrow Artist</label><p style="color:#976b03;">$ 980</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1">
                            <div class="pro-box2">
                                <label class="frm-label pb-0">Creative Director</label><p style="color:#976b03;">$1280</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1">
                            <div class="pro-box2">
                                <label class="frm-label pb-0">Principal</label><p style="color:#976b03;">$1980</p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
    export default {
        data(){
            return{
                logo: require("@/assets/images/logo.png"),
                  tax_info_details:{},
                  DynamicFlagArray:[],
                  DynamicproductFlagArray:[],
                  active_el:"",
                  service_active_el:"",
                  product_active_el:""
            }
        },
    
        mounted() {
            this.getcategorylist();
            this.getproductlist();
            this.navLinkOnclick('pills-home-tab');
        },
        computed:{
            ...mapGetters([
                "getPackages",
            ]),
        },

        methods:{
            //Nav Link OnClick
            navLinkOnclick:function(el){
                this.active_el = el;
            },

            getcategorylist() {
                this.$apiCall.get(this.$apiBus.ViewServiceInfo, {
                    params : {
                            ITEM: "VIEW_ALL",
                            CATEGORY_SYS_ID:0,
                            PAGE_SIZE: 10,
                            PAGE_NO:1,
                        }
                
                }).then(res => {
                        this.tax_info_details = res.response;
                        this.DynamicFlagArray = res;
                });
            },

            getproductlist() {
                this.$apiCall.get(this.$apiBus.product_list, {
                    params : {
                        ITEM: "VIEW_ALL",

                        RECORD_SYS_ID:0,
                        PRODUCT_SKU_CODE: "",

                        BRAND_NAME:"",
                        SERIES_NAME:"",
                        PRODUCT_TYPE:"",
                        BAR_CODE:"",
                        PRODUCT_NAME:""
                    }
        
                }).then(res => {
                    this.tax_info_details = res.response;
                    this.DynamicproductFlagArray = res;
                });
            },

            getDataForPackages(services,itemType,itemCategoryName){
                // let items = JSON.parse(JSON.stringify(this.getPackages));
                let items = this.getPackages;
                const serviceObj = services;
               // serviceObj['ORDER_DETAILS_ID']=0;
                //serviceObj['ITEM_SKU_CODE'] = services.SKU_REF_CODE;
                serviceObj['QTY'] = 1;
                serviceObj['PACKAGE_DETAILS_ID']=0;
                //serviceObj['CURRENCY_SYS_ID'] = 1;
                // serviceObj['UNIT_PRICE'] = serviceObj.SELL_PRICE;
                // serviceObj['DISCOUNT_VALUE']= 0;
                // serviceObj['TOTAL_PRICE'] = serviceObj.SELL_PRICE;
                serviceObj["ITEM_TYPE"] = itemType;

                serviceObj["ITEM_CATEGORY_NAME"] = itemCategoryName;
                if (items.length > 0) {
                    let cart_item = items.find(i => (i.ITEM_ID == serviceObj.ITEM_ID && i.ITEM_TYPE == serviceObj.ITEM_TYPE));
                    console.log("cart_item",cart_item);
                    if (cart_item) {
                        let index = items.indexOf(cart_item);
                        items[index].QTY += 1;
                        // items[index].TOTAL_PRICE = (parseInt(items[index].QTY) * parseInt(items[index].UNIT_PRICE));
                        this.$store.commit("updatePackages", items);
                        this.$forceUpdate();
                        
                    } else {
                        items.push(serviceObj);
                        this.$forceUpdate();
                        this.$store.commit('addPackages', items);
                    }
                } else {
                    items.push(serviceObj);
                    this.$store.commit('addPackages', items);
                    this.$forceUpdate();
                }
                
            },


        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/style.scss";
.side-navbar .active {
    color: #fff;
    background-image: linear-gradient(to right, #BD9B36 , #976b03) !important;
}
.wrapper .side-navbar ul li {
    padding: 0px;
    margin-top: 0px;
    border-bottom: 0px;
    box-shadow:none;
    color: #212121;
}
.wrapper .side-navbar li:hover {
    background: none;
    color: #212121;

}
.nav-link:hover, .nav-link:focus {
    color: #212121 !important;
}
.nav-link:hover, .nav-link:focus {
    color: #212121;
}
.categories-data .active {
    background: none !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}
</style>