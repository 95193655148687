import { data } from "jquery";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products:[],
    packages:[],
    loading: false,
    is_packages: false,
    category_list:[],
    sub_category_list:[],
    products_list:[],
    side_bar_loading:false,
    service_list:[],
    medicalformData:{
      HEART_ATTACK:null,
      HIGH_BLOOD_PRESSURE:null,
      GYNE_DISEASE:null,
      ASTHMA:null,
      CANCER:null,
      BONE_METAL_IMPLANT:null,
      DIABETES:null,
      CONSTIPATION:null,
      KIDNEY_DISEASE:null,
      OTHERS:null,
      DEPRESSION_ANXIETY:null,
      INSOMNIA:null,
      BONE_METAL_IMPLANT:null,
      DIABETES:null,
      CONSTIPATION:null,
      KIDNEY_DISEASE:null,
      OTHERS:null,
      DEPRESSION_ANXIETY:null,
      INSOMNIA:null,
      SKIN_INFLAMMATION_ALLERGY:null,
      PREGNANCY:null,
      NONE_OF_ABOVE:null,
      BACK_WAIST:null,
      BREAST_CHEST:null,
      NECK_SHOULDER_RHEUMATISM:null,
      LYMPHATIC_BLOCK:null,
      STOMACH_KIDNEY:null,
      OVARIAN_HIP_AREA:null,
      STRESS:null,
      ENDOCRINE_IMBALANCE:null,
      LIVER_GALL:null,
      HEADACHE_INSOMNIA:null,
      HEART_LUNGS:null,
      WEAKNESS_COLD:null,
      EXCESS_FAT:null,
      LIMBS:null,
      BLOCKED_MERIDIANS:null,
      HEATY_DAMP:null,
      DARK_EYE_CIRCLES:null,
      EYE_BAG:null,
      CHOLESTEROL_OIL_SPOTS:null,
      SCARS:null,
      SAGGY:null,
      FRECKLES:null,
      DEHYDRATED:null,
      SENSITIVE:null,
      CREATED_BY:null,
    },
    customerPackageDetails:[],
    customerTransactionsData:[],
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getPackages(state) {
      return state.packages;
    },
    loading: state => state.loading,
    sideBarLoading: state => state.side_bar_loading,
    isPackage: state => state.is_packages,
    getProductsList(state) {
      return state.products_list;
    },
    getCategoryList(state) {
      return state.category_list;
    },
    getSubCategoryList(state) {
      return state.sub_category_list;
    },
    getServiceList(state) {
      return state.service_list;
    },
    customerPackageDetails(state){
      return state.customerPackageDetails;
    },
    customerTransactionsData(state){
      return state.customerTransactionsData;
    },
    medicalformData(state){
      return state.medicalformData;
    }
  },
  mutations: {
    addProducts(state, products){
      state.products = products;
    },

    updateCart(state, items) {
      state.products = items;
    },

    addPackages(state, data){
      state.packages = data;
    },

    updatePackages(state, items) {
      state.packages = items;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setSidebarLoading(state, loading){
      state.side_bar_loading = loading;
    },
    clickPackages(state, data){
      state.is_packages = data;
    },
    addCategories(state, list){
      state.category_list = list;
    },
    addSubCategories(state, list){
      state.sub_category_list = list;
    },
    addServices(state, list){
      state.service_list = list;
    },
    updateSubCategories(state, items) {
      state.category_list = items;
    },
    addProductsList(state, list){
      state.products_list = list;
    },
    // customer page
    addcustomerTransactionsData(state, list){
      state.customerTransactionsData = list;
    },
    addCustomerPackageDetails(state, items) {
      state.customerPackageDetails = items;
    },
    addMedicalform(state, list){
      state.medicalformData = list;
    }
  },
  actions: {
  },
  modules: {},
});
