<template>
    <div>
        <div class="wrapper">
            <div class="container-fluid d-flex">
                <aside>
                    <side-bar></side-bar>
                </aside>
                <div class="main-cont">
                    <div class="container-fluid">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SideBar from '@/components/SideBar.vue'
export default {
    components:{
        SideBar
    },
    
}
</script>